import React from 'react';
import {Badge, Card, ProgressBar, Accordion, Table} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faBuilding, faCommentDollar } from '@fortawesome/free-solid-svg-icons'
import './CompanyProfile.css'

class PersonProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
        personUuid: props.personUuid,
        person: null,
        loading: true
    };
  }

  loadPerson() {
    fetch(process.env.REACT_APP_API_URL+"/person/"+this.props.personUuid, {
        method: 'GET',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
      }).then(response => response.json())
      .then(data => {
        this.setState({person: data, loading: false})
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  render() {
    
    if (this.state.loading) {
        return <div>
            <ProgressBar animated now={100} />
        </div>
    }
    return <div>
            <Card>
            <Card.Body>
                <Card.Title>{this.state.person.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted"><Badge bg="dark"><FontAwesomeIcon icon={faLocationArrow} /> {this.state.person.full_location && this.state.person.full_location.label ? this.state.person.full_location.label : 'Unknown'}</Badge> <a href={this.state.person.linkedin_url}>{this.state.person.linkedin_url}</a></Card.Subtitle>
                <Card.Text>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><FontAwesomeIcon className='headerIcon' icon={faBuilding} /> Employments<Badge className='badgeCounter' bg="secondary">{this.state.person.employments.length}</Badge></Accordion.Header>
                            <Accordion.Body>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                    <th>Title</th>
                                    <th>Company</th>
                                    <th>Dates</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.person.employments.map(employment =>
                                        <tr href={"/companies/"+employment.company.uuid}>
                                            <td>{employment.title}</td>
                                            <td><a href={"/companies/"+employment.company.uuid}>{employment.company.name}</a></td>
                                            <td>{employment.from} - {employment.to ? employment.to : 'Present'}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><FontAwesomeIcon className='headerIcon' icon={faCommentDollar} /> Deals<Badge className='badgeCounter' bg="secondary">{this.state.person.deals.length}</Badge></Accordion.Header>
                            <Accordion.Body>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Deal</th>
                                        <th>Role in deal</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.person.deals.map(deal => <tr>
                                            <td><a href={"/deals/"+deal.uuid}>{deal.nickname}</a></td>
                                            <td>{deal.type}</td>
                                            <td><a rel="noreferrer" className="btn btn-primary" href={"/deals?selectedDeal="+deal.uuid} target="_blank">Edit</a></td>
                                    </tr>)}
                                </tbody>
                            </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                </Card.Text>
            </Card.Body>
            </Card>
    </div>
  }

  componentDidMount() {
      this.loadPerson()
  }
}

export default PersonProfile;
