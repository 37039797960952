import { useLocation } from "react-router-dom";
import queryString from "query-string";
import DealsPage from "./DealsPage";
function DealsPageWrapper(Component) {
    let location = useLocation()
    console.log(location.search)
    let query = queryString.parse(location.search)
    return <DealsPage selectedDealUuid={query.selectedDeal} />
}

export default DealsPageWrapper;