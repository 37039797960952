import React from 'react';
import {Badge, Card, ProgressBar, Accordion, Table, Col, Row, Modal, Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faUsers, faCommentDollar, faBuilding, faPenSquare } from '@fortawesome/free-solid-svg-icons'
import './CompanyProfile.css'
import LaravelValidationErrors from './LaravelValidationErrors';
import AddCompany from './AddCompany';
import { toast } from 'react-toastify';
import autoBind from 'react-autobind';
class CompanyProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
        companyUuid: props.companyUuid,
        company: null,
        errors: [],
        showEdit: false,
        loading: true
    };
    autoBind(this)
  }

  loadCompany() {
    fetch(process.env.REACT_APP_API_URL+"/company/"+this.props.companyUuid, {
        method: 'GET',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
      }).then(response => response.json())
      .then(data => {
        this.setState({company: data, loading: false})
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  editCompany(company, uuid) {
    return fetch(process.env.REACT_APP_API_URL+"/company/"+uuid, {
      method: 'PUT',
      headers: {
        'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
      body: JSON.stringify(company),
    }).then(response => response.json())
    .then(data => {
      if(data.errors) {
        this.setState({errors: data.errors})
      } else {
        this.setState({
          showEdit: false,
          company: data
        })
        toast.success("Updated "+company.name)
      }
      return data
    })
    .catch((error) => {
      toast.error("Failed to update company")
      console.error('Error:', error);
    });
  }
  render() {
    
    if (this.state.loading) {
        return <div>
            <ProgressBar animated now={100} />
        </div>
    }
    return <div>
            <Modal size="lg" show={this.state.company && this.state.showEdit} onHide={() => this.setState({showEdit: false})}>
              <Modal.Header closeButton>
                <Modal.Title>Edit company</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <LaravelValidationErrors errors={this.state.errors} />
                <AddCompany submitCallback={this.editCompany} selected={this.state.company}/>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({showEdit: false})}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <Card>
            <Card.Body>
                <Card.Title>{this.state.company.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted"><a href={this.state.company.website}>{this.state.company.website}</a></Card.Subtitle>
                <Card.Text>
                    <div className='CompanySectors'>
                      <Badge bg="dark"><FontAwesomeIcon icon={faLocationArrow} /> {this.state.company.hq_location && this.state.company.hq_location.label ? this.state.company.hq_location.label : 'Unknown'}</Badge> {this.state.company.sectors.length ? this.state.company.sectors.map(sector => <Badge bg="primary">{sector.label}</Badge>) : null}
                      {' '}<Badge bg="secondary"><FontAwesomeIcon icon={faBuilding} /> {this.state.company.is_public ? 'Public company' : 'Private company'}</Badge>
                    </div>
                    <div>
                      <Button variant="dark" onClick={() => this.setState({showEdit: true})}><FontAwesomeIcon icon={faPenSquare} /> Edit</Button>
                    </div>
                    <hr/>
                    <Row>
                    <Col xs={12} md={12} lg={5}>
                      <Accordion>
                          <Accordion.Item eventKey="0">
                              <Accordion.Header><FontAwesomeIcon className='headerIcon' icon={faUsers} /> Employees<Badge className='badgeCounter' bg="secondary">{this.state.company.employees.length}</Badge></Accordion.Header>
                              <Accordion.Body>
                              <Table striped bordered hover size="sm">
                                  <thead>
                                      <tr>
                                      <th>Name</th>
                                      <th>Title</th>
                                      <th>Dates</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {this.state.company.employees.map(employee =><tr href={"/people/"+employee.uuid}>
                                              <td><a href={"/people/"+employee.uuid}>{employee.name}</a></td>
                                              <td>{employee.employment.title}</td>
                                              <td>{employee.employment.from} - {employee.employment.to ? employee.employment.to : 'Present'}</td>
                                              </tr>)}
                                  </tbody>
                              </Table>
                              </Accordion.Body>
                          </Accordion.Item>
                      </Accordion>
                    </Col>
                    <Col xs={12} md={12} lg={7}>
                      <Accordion>
                          <Accordion.Item eventKey="0">
                              <Accordion.Header><FontAwesomeIcon className='headerIcon' icon={faCommentDollar} />Deals<Badge className='badgeCounter' bg="secondary">{this.state.company.deals.length}</Badge></Accordion.Header>
                              <Accordion.Body>
                              <Table striped bordered hover size="sm">
                                  <thead>
                                      <tr>
                                      <th>Deal</th>
                                      <th>Deal type</th>
                                      <th>Role in deal</th>
                                      <th>Actors</th>
                                      <th></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {this.state.company.deals.map(deal =><tr href={"/deals/"+deal.uuid}>
                                              <td><a rel="noreferrer" href={"/deals/"+deal.uuid} target="_blank">{deal.name}</a></td>
                                              <td>{deal.type}</td>
                                              <td>{deal.company_role}</td>
                                              <td><ul>{deal.actors.map(actor => <li>
                                                      {actor.name} ({actor.role})
                                              </li>)}</ul></td>
                                              <td><a rel="noreferrer" className="btn btn-primary" href={"/deals?selectedDeal="+deal.uuid} target="_blank">Edit</a></td>
                                              </tr>)}
                                  </tbody>
                              </Table>
                              </Accordion.Body>
                          </Accordion.Item>
                          </Accordion>
                        </Col>
                        </Row>
                </Card.Text>
            </Card.Body>
            </Card>
    </div>
  }

  componentDidMount() {
      this.loadCompany()
  }
}

export default CompanyProfile;
