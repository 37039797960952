import React from 'react';
import AddDeal from "./AddDeal";
import DealList from "./DealList";
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";
import LaravelValidationErrors from './LaravelValidationErrors';
import ConfirmationModal from './ConfirmationModal';
import autoBind from 'react-autobind';
import {ListGroup, Badge} from 'react-bootstrap';

class DealsPage extends React.Component {
  constructor() {

    super();
    this.state = {
      deals: [],
      selectedDeal: {},
      selectedIndex: null,
      deleteModalOpen: false,
      deleteModalErrors: null,
      deleteModalContent: null,
      errors: {},
      loading: true,
    };
    autoBind(this);
  }

  componentDidMount() {
    this.getDeals().then(() => {
      if(this.props.selectedDealUuid) {
        this.selectDealFromUuid(this.props.selectedDealUuid)
      }
    });
  }

  sendDealtoApi(dealBody) {
    this.setState({errors: {}})
    return fetch(process.env.REACT_APP_API_URL+"/deal", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
      body: JSON.stringify(dealBody),
    }).then(response => {
      return response.json()
    })
    .then(data => {
      if(data.errors) {
        this.setState({errors: data.errors})

      } else {
        toast.success("Added deal with success")
        let deals = this.state.deals;
        deals.unshift(data)
        this.setState({deals: deals})
      }
      return data
    })
    .catch((error) => {
      console.error('Error:', error);
      toast.error("Unknown error")
    });
  }


  updateDeal(dealBody, dealUUID) {
    this.setState({errors: {}})
    return fetch(process.env.REACT_APP_API_URL+"/deals/"+dealUUID, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
      body: JSON.stringify(dealBody),
    }).then(response => {
      return response.json()
    })
    .then(data => {
      if(data.errors) {
        this.setState({errors: data.errors})

      } else {
        toast.success("Updated deal with success")
        this.setState({deals: data, selectedDeal: {}, selectedIndex: null})

      }
      return data
    })
    .catch((error) => {
      console.error('Error:', error);
      toast.error("Unknown error")
    });
  }

  getDeals() {
    return fetch(process.env.REACT_APP_API_URL+"/deals", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
    }).then(response => {
      if(!response.ok) {
        toast.error("Failed to list deals")
        this.setState({loading: false})
      }
      return response.json()
    })
    .then(data => {
      this.setState({deals: data, loading: false})
      console.log(data)
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  confirmDeleteDeal() {
    return fetch(process.env.REACT_APP_API_URL+"/deals/"+this.state.selectedDeal.deal.uuid, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
    }).then(response => response.json())
    .then(data => {
      if(data.errors) {
        this.setState({
          deleteModalErrors: this.renderDeleteError(data.errors)
        })
      } else {
        toast.success('Deleted '+this.state.selectedDeal.deal.nickname)
        let deals = this.state.deals
        deals.splice(this.state.selectedIndex, 1)
        this.setState({
          selectedDeal: {},
          selectedIndex: null,
          deals: deals,
          deleteModalErrors: null,
          deleteModalOpen: null,
          deleteModalContent: null
        }) 
        
      }
      return data
    })
    .catch((error) => {
      toast.error('Failed to delete')
      console.error('Error:', error);
    });
  }

  renderDeleteError(errors) {
    return <div>
        Failed to delete, deal is linked with the following resources:
        <ListGroup>
          {errors.map(error => <ListGroup.Item key={error.uuid+error.type} action target="_blank" href={error.type === 'deal' ? '/deals/'+error.uuid : '/people/'+error.uuid}>{error.name} <Badge className='pullRight' bg="dark">{error.type}</Badge></ListGroup.Item>)}
        </ListGroup>
    </div>
  }

  deleteDeal(index) {
    let selectedDeal = this.state.deals[index]
    this.setState({
      selectedDeal: selectedDeal,
      selectedIndex: index,
      deleteModalOpen: true,
      deleteModalContent: selectedDeal.deal.nickname+' is about to be deleted, Are you sure ?'
    })
  }

  selectDeal(index) {
    console.log("Deal selected")
    let selectedDeal = this.state.deals[index]
    if(!selectedDeal) {
      console.log("Deal not found")
      return null
    }
    console.log(selectedDeal)
    this.setState({
      selectedDeal: selectedDeal,
      selectedIndex: index
    })
  }

  selectDealFromUuid(uuid) {
    const uuidIndex = this.state.deals.findIndex((deal) => deal.deal.uuid === uuid)
    console.log(uuidIndex)
    if(uuidIndex === -1) {
      return null
    }
    this.selectDeal(uuidIndex)
  }
  render() {
    return <div className='peoplePageMargins'>
        <ConfirmationModal 
            open={this.state.deleteModalOpen}
            handleCancel={() => this.setState({deleteModalErrors: null, deleteModalOpen: false, deleteModalContent: null})}
            title={'Deal deletion'}
            content={this.state.deleteModalContent}
            error={this.state.deleteModalErrors}
            confirmText={'Delete'}
            handleConfirm={this.confirmDeleteDeal}
          />
        <div className='addCompanyContainer'>
            <AddDeal selected={this.state.selectedDeal} deals={this.state.deals} submitCallback={this.sendDealtoApi} updateCallback={this.updateDeal}/>
            <LaravelValidationErrors noTitles={true} errors={this.state.errors} />
        </div>
        <div className='personListContainer'>
          <hr/>
        {this.state.loading ? <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} /> : null}
            <DealList selectedIndex={this.state.selectedIndex} deals={this.state.deals} selectCallback={this.selectDeal} deleteCallback={this.deleteDeal}/>
        </div>
    </div>;
  }
}

export default DealsPage;
